import type { AxiosError } from "axios";
import { deletePasskey } from "../api/account";

type PasskeyErrorDetails = {
  message: string;
  code: "login_required" | "server_error" | "invalid_request" | "unknown_error";
  data?: unknown; // 追加: エラー詳細用のフィールド
};

export const useRevokePasskey = () => {
  const revokePassKey = async (id: string) => {
    try {
      await deletePasskey({ id });
    } catch (e) {
      const response = (e as AxiosError).response;
      if (response?.status === 400) {
        throw {
          message: "invalid request",
          code: "invalid_request",
          data: response?.data,
        } as PasskeyErrorDetails;
      }
      throw {
        message: "Network or server error occurred",
        code: "server_error",
        data: response?.data,
      } as PasskeyErrorDetails;
    }
  };

  return {
    revokePassKey,
  };
};
