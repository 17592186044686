import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_CONTRACT_CANCEL_QUESTIONNAIRE } from "./apiPaths";

export type ContractCancelQuestionnaireResponse = {
  close_questionnaire_info?: {
    question1?: string;
    question2?: string;
    question3?: string;
    question4?: string;
    question5?: string;
    question6?: string;
    question7?: string;
    question8?: string;
    question9?: string;
    question10?: string;
    question_text?: "0" | "1";
  };
};

const getUrl = (serviceId: string): string => {
  return ACCOUNT_API_CONTRACT_CANCEL_QUESTIONNAIRE.replace(
    "<client_id>",
    serviceId,
  );
};

export const getContractCancelQuestionnaire = async (
  clientId: string,
  planId: string,
): Promise<AxiosResponse<ContractCancelQuestionnaireResponse>> => {
  return axios.get(`${getUrl(clientId)}?plan_id=${planId}`, {
    withCredentials: true,
  });
};
