import axios, { type AxiosResponse } from "axios";
import { ACCOUNT_API_CONTRACT_CANCEL_CHECK } from "./apiPaths";

export type ContractCancelCheckResponse = {
  termination_info_group: {
    service_id: string;
    service_name: string;
    plan_id: string;
    plan_name: string;
    service_free_type: "1" | "2" | "3" | "4";
    plan_charge_type: "1" | "2";
    request_status: "0" | "1" | "9";
    apply_date?: string;
    first_month_penalty_info?: {
      payment_date: string;
      price: number;
      set_discounted_price: number;
      set_discount_flg: "0" | "1";
    };
    discount_penalty_info?: {
      payment_date: string;
      price: number;
    };
  }[];
};

const getUrl = (serviceId: string): string => {
  return ACCOUNT_API_CONTRACT_CANCEL_CHECK.replace("<client_id>", serviceId);
};

export const getContractCancelCheck = (
  clientId: string,
  planId: string,
): Promise<AxiosResponse<ContractCancelCheckResponse>> => {
  return axios.get(getUrl(clientId), {
    params: {
      plan_id: planId,
    },
    withCredentials: true,
  });
};
