import * as React from "react";

import { clsx } from "clsx";

import { Button as FoundationButton } from "../../foundations/Button";

import { Box } from "../../foundations/Box";
import type {
  ButtonElement as FoundationButtonElement,
  ButtonProps as FoundationButtonProps,
} from "../../foundations/Button";

type VariantType = "solid" | "border" | "no-border" | "link";
type ColorType = "blue" | "red";
type ButtonElement = FoundationButtonElement;

type ButtonBaseProps = FoundationButtonProps & {
  variant?: VariantType;
  color?: ColorType;
  size?: "sm" | "md" | "lg" | "full";
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

const useButtonState = (props: ButtonBaseProps) => {
  const { variant, size, disabled, startIcon, endIcon, className, ...rest } =
    props;
  return { ...rest };
};

const ButtonBase = React.forwardRef<ButtonElement, ButtonBaseProps>(
  (props, forwardedRef) => {
    const variant = props.variant || "solid";
    const color = props.color || "blue";
    const size = props.size ? `nid-button-${props.size}` : "";
    const nidClass = clsx(
      "nid-button",
      `nid-button-${variant}`,
      `nid-button-color-${color}`,
      size,
      {
        "w-full": size === "full",
        "nid-button-disabled": props.disabled,
      },
      variant === "link" && "nid-typography--color-link",
      props.className,
    );

    const state = useButtonState(props);

    // Link button
    if (variant === "link") {
      return (
        <Box className="nid-button-link-container">
          <FoundationButton
            className={nidClass}
            disabled={props.disabled}
            {...forwardedRef}
            {...state}
          >
            {props.children}
          </FoundationButton>
        </Box>
      );
    }

    return (
      <FoundationButton
        className={nidClass}
        disabled={props.disabled}
        {...forwardedRef}
        {...state}
      >
        {props.startIcon}
        {props.children}
        {props.endIcon}
      </FoundationButton>
    );
  },
);

ButtonBase.displayName = "Nid.button";
const Button = ButtonBase;
const Root = ButtonBase;
export { Root, ButtonBase, Button };
export type { ButtonBaseProps };
export default ButtonBase;
