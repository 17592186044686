declare global {
  interface Window {
    atlasTracking?: {
      trackAction: (
        action: string,
        category: string,
        events: string,
        object: object,
      ) => void;
    };
    analyticsVar: {
      context?: object;
    };
  }
}

// 必要に応じて型を追加してください
type ClickButtonEvent = "cancel_nkdk";
type PerformanceEvent =
  | "begin_signin"
  | "begin_signup"
  | "close_passkey_prompt"
  | "create_passkey"
  | "end_signin"
  | "end_signup"
  | "verify_backup_code"
  | "verify_mail_address"
  | "verify_passkey"
  | "verify_password"
  | "verify_otp_email"
  | "verify_otp_app"
  | "verify_otp_challenge"
  | "unknown_error";

export const track = {
  clickButton: (event: ClickButtonEvent, customVars: object) => {
    window?.atlasTracking?.trackAction("click", "button", event, {
      custom_vars: customVars,
    });
  },
  performance: (event: PerformanceEvent, customVars: object) => {
    window?.atlasTracking?.trackAction("track", "performance", event, {
      custom_vars: customVars,
    });
  },
};

const toStr = (reason: unknown): string => {
  if (typeof reason === "string") return reason;
  if (reason instanceof Error) return reason.message;
  return JSON.stringify(reason);
};

const beginSignIn = (flow: "passkey" | "password") => {
  track.performance("begin_signin", { result: "success", flow });
};

const beginSignInFailed = (reason: unknown) => {
  track.performance("begin_signin", {
    result: "failure",
    reason: toStr(reason),
  });
};

const beginSignUp = () => {
  track.performance("begin_signup", { result: "success" });
};

const beginSignUpFailed = (reason: unknown) => {
  track.performance("begin_signup", {
    result: "failure",
    reason: toStr(reason),
  });
};

const closePasskeyPrompt = (
  reason: "not_allowed" | "unexpected",
  detail?: unknown,
) => {
  track.performance("close_passkey_prompt", { reason, detail: toStr(detail) });
};

const createPasskey = (credentialId: string) => {
  track.performance("create_passkey", { result: "success", credentialId });
};

const createPasskeyFailed = (reason: unknown) => {
  track.performance("create_passkey", {
    result: "failure",
    reason: toStr(reason),
  });
};

const endSignIn = () => {
  track.performance("end_signin", { result: "success" });
};

const endSignUp = () => {
  track.performance("end_signup", { result: "success" });
};

const verifyBackupCode = () => {
  track.performance("verify_backup_code", { result: "success" });
};

const verifyBackupCodeFailed = (reason: unknown) => {
  track.performance("verify_backup_code", {
    result: "failure",
    reason: toStr(reason),
  });
};

const verifyMailAddress = () => {
  track.performance("verify_mail_address", { result: "success" });
};

const verifyMailAddressFailed = (reason: unknown) => {
  track.performance("verify_mail_address", {
    result: "failure",
    reason: toStr(reason),
  });
};

const verifyPasskey = (
  mediation: "conditional" | undefined,
  credentialId: string,
) => {
  track.performance("verify_passkey", {
    result: "success",
    mediation,
    credentialId,
  });
};

const verifyPasskeyFailed = (
  mediation: "conditional" | undefined,
  reason: unknown,
) => {
  track.performance("verify_passkey", {
    result: "failure",
    mediation,
    reason: toStr(reason),
  });
};

const verifyPassword = () => {
  track.performance("verify_password", { result: "success" });
};

const verifyPasswordFailed = (reason: unknown) => {
  track.performance("verify_password", {
    result: "failure",
    reason: toStr(reason),
  });
};

const verifyPasswordAndChallengeRequired = () => {
  track.performance("verify_password", {
    result: "challenge_required",
  });
};

const verifyOtp = (type: "email" | "app" | "challenge") => {
  track.performance(`verify_otp_${type}`, { result: "success" });
};

const verifyOtpFailed = (
  type: "email" | "app" | "challenge",
  reason: unknown,
) => {
  track.performance(`verify_otp_${type}`, {
    result: "failure",
    reason: toStr(reason),
  });
};

const unknownError = (reason: unknown) => {
  track.performance("unknown_error", {
    result: "failure",
    reason: toStr(reason),
  });
};

export const AtlasTracking = {
  track,
  beginSignIn,
  beginSignInFailed,
  beginSignUp,
  beginSignUpFailed,
  closePasskeyPrompt,
  createPasskey,
  createPasskeyFailed,
  endSignIn,
  endSignUp,
  verifyBackupCode,
  verifyBackupCodeFailed,
  verifyMailAddress,
  verifyMailAddressFailed,
  verifyPasskey,
  verifyPasskeyFailed,
  verifyPassword,
  verifyPasswordFailed,
  verifyPasswordAndChallengeRequired,
  verifyOtp,
  verifyOtpFailed,
  unknownError,
};
