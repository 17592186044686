export const ACCOUNT_API_STATUS = "/v2/account/status";
export const ACCOUNT_API_USERINFO = "/v2/account/userinfo";
export const ACCOUNT_API_MASTER_JOB_INFO = "/v2/account/master/job-info";
export const ACCOUNT_API_MASTER_ADDRESS = "/v2/account/master/address";
export const ACCOUNT_API_MASTER_COUNTRIES = "/v2/account/master/countries";
export const ACCOUNT_API_MASTER_COMPANY_TYPE =
  "/v2/account/master/company-type";
export const ACCOUNT_API_EXTERNAL_COMPANY_SUGGESTIONS =
  "https://ac.n8s.jp/v1/_/companies";
export const ACCOUNT_API_PASSWORD = "/v2/account/password";
export const ACCOUNT_API_LOGIN_ID_CHANGE_START =
  "/v2/account/login-id/change/start";
export const ACCOUNT_API_LOGIN_ID_CHANGE_CONFIRM =
  "/v2/account/login-id/change/confirm";
export const ACCOUNT_API_START_SIGN_IN = "/v2/account/signin/start";
export const ACCOUNT_API_SIGN_IN_CALLBACK = "/v2/account/signin/callback";
export const ACCOUNT_API_MFA_STATUS = "/v2/account/mfa/status";
export const ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_START =
  "/v2/account/mfa/setup/authenticator/start";
export const ACCOUNT_API_MFA_SETUP_AUTHENTICATOR_CONFIRM =
  "/v2/account/mfa/setup/authenticator/confirm";
export const ACCOUNT_API_MFA_SETUP_EMAIL_START =
  "/v2/account/mfa/setup/email/start";
export const ACCOUNT_API_MFA_SETUP_EMAIL_CONFIRM =
  "/v2/account/mfa/setup/email/confirm";
export const ACCOUNT_API_MFA_BACKUP_CODE = "/v2/account/mfa/backup-code";
export const ACCOUNT_API_MFA_DISABLE = "/v2/account/mfa/disable";
export const ACCOUNT_API_WITHDRAW = "/v2/account/withdraw";
export const ACCOUNT_API_REGISTERED_RELYING_PARTIES =
  "/v2/account/registered-relying-parties";
export const ACCOUNT_API_REGISTERED_RELYING_PARTY =
  "/v2/account/registered-relying-parties/<client_id>";
export const ACCOUNT_API_PAYMENTS_CARDS = "/v2/account/payments/cards";
export const ACCOUNT_API_PAYMENT_HISTORIES = "/v2/account/payments/histories";
export const ACCOUNT_API_PAYMENT_HISTORY_DETAIL =
  "/v2/account/payments/histories/<payment_id>";
export const ACCOUNT_API_CONTRACT_DETAIL =
  "/v2/account/services/<client_id>/contract";
export const ACCOUNT_API_CONTRACT_CANCEL =
  "/v2/account/services/<client_id>/contract/cancel";
export const ACCOUNT_API_CONTRACT_CANCEL_CHECK =
  "/v2/account/services/<client_id>/contract/cancel/check";
export const ACCOUNT_API_CONTRACT_CANCEL_QUESTIONNAIRE =
  "/v2/account/services/<client_id>/contract/cancel/questionnaires";
export const ACCOUNT_API_CONTRACT_CANCEL_UNDO =
  "/v2/account/services/<client_id>/contract/cancel/undo";
export const ACCOUNT_API_PASSWORD_RESET_START =
  "/v2/account/password/reset/start";
export const ACCOUNT_API_PASSWORD_RESET_TOKEN_CONFIRM =
  "/v2/account/password/reset/token/confirm";
export const ACCOUNT_API_PASSWORD_RESET = "/v2/account/password/reset";
export const ACCOUNT_API_PASSKEYS = "/v2/account/passkeys";
export const ACCOUNT_API_PASSKEYS_CREATION_OPTIONS =
  "/v2/account/passkeys/creation-options";
export const ACCOUNT_API_PASSKEYS_CREATE = "/v2/account/passkeys/create";
export const ADS_SEGMENTS = (() => {
  const url = `${import.meta.env.VITE_APIGW_HOST}/ads/v1/segments`;
  const popUpFileName = import.meta.env.VITE_CAMPAIGN_CANCEL_POPUP;
  return popUpFileName?.includes("dev") ? new URL(url).pathname : url;
})();
export const PROMOTION_CAMPAIGN_CANCEL_POPUP = (() => {
  const url =
    "https://www.nikkei.com/promotion/campaign/cancel_page_popup_idrp";
  const popUpFileName = import.meta.env.VITE_CAMPAIGN_CANCEL_POPUP;
  return popUpFileName?.includes("dev") ? new URL(url).pathname : url;
})();
