import useSWR from "swr";
import { getPasskeys } from "../api/account";

export const usePasskeys = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "/account/passkeys",
    getPasskeys,
  );

  const mutateFunction: () => void = mutate;

  if (error) {
    return {
      status: "error",
      error,
      data: undefined,
      mutate: mutateFunction,
    } as const;
  }
  if (isLoading || data === undefined) {
    return {
      status: "loading",
      data: undefined,
      mutate: mutateFunction,
    } as const;
  }

  return { status: "ok", data: data.data, mutate: mutateFunction } as const;
};
